<template>
  <div>
    <div class="content-design" @click='showtype=false'>
      <div class="all">
        <div class="table">
          <el-tabs v-model="activeName" @tab-click="handleClick" >
            <el-tab-pane label="待配置" name="first"></el-tab-pane>
            <el-tab-pane label="已配置" name="seco"></el-tab-pane>
          </el-tabs>
          <!--数据表格-->
          <div class="SearchBar"  v-if="activeName!='first'">
            <!--搜索框-->
            <el-form ref="search" :inline="true" :model="search" size="small" label-width="90px" >

              <el-form-item label="工程编号/名称" label-width="110px">
                <el-input
                    v-model="search.keyWord"
                    placeholder="请输入档案号/合同编号"
                    class="SearchInput"
                    clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="工程类型">
                <el-select v-model="search.projectType" clearable placeholder="请选择">
                  <el-option
                      v-for="item in TypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="工程状态">
                <el-select v-model="search.status" clearable placeholder="请选择">
                  <el-option
                      v-for="item in statusList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label=" " label-width="10" >
                <div>
                  <el-button type="primary"  size="small" @click="getList(1)">查询</el-button>
                  <el-button type="primary" plain  size="small" @click="reset">重置</el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <el-table v-if="activeName=='first'"
                    ref="tableData"
                    :data="tableData"
                    style="width: 100%"
                    border
                    :stripe="true"
                    height="calc(100vh - 315px)"
          >

            <el-table-column
                type="index"
                label="序号"
                width="70" align="center">
            </el-table-column>
            <el-table-column
                prop="projectNumber"
                label="工程编号"
                align="center"
                show-overflow-tooltip
            > </el-table-column>
            <el-table-column
                prop="projectName"
                label="工程名称"
                align="center"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="projectType"
                label="工程类型"
                align="center"
                show-overflow-tooltip

            >
            </el-table-column>
            <el-table-column
                prop="realname"
                label="登记人"
                align="center"
                show-overflow-tooltip
                width="100px"
            >
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="登记时间"
                align="center"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="status"
                label="状态"
                align="center"
                show-overflow-tooltip
                width="100"
            >
              <template slot-scope="{ row }">
                <el-tag v-if="row.statusName" :type="row.statusName[row.statusName.length-1]=='中'?'warning':(row.statusName[row.statusName.length-1]=='成'?'success':'')">{{ row.statusName }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left"  width="200">
              <template slot-scope="{ row }">
                <el-button size="small" type="primary" @click="configMenu(row)">资料配置</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              :current-page="searchfirst.current"
              :background="true"
              v-if="activeName=='first'"
              :page-sizes="[ 30, 50, 100]"
              :page-size="searchfirst.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handlefirstSizeChange"
              @current-change="handlefirstCurrentChange"
          >
          </el-pagination>
<!--          第二分页-->
          <el-table v-if="activeName!='first'"
                    ref="tableData"
                    :data="recordList"
                    height="calc(100vh - 370px)"
                    border
          >

            <el-table-column
                type="index"
                label="序号"
                width="70" align="center">
            </el-table-column>

            <el-table-column
                prop="projectNumber"
                label="工程编号"
                align="center"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="projectName"
                label="工程名称"
                align="center"
                show-overflow-tooltip

            >
            </el-table-column>
            <el-table-column
                prop="projectType"
                label="工程类型"
                align="center"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="realname"
                label="登记人"
                align="center"
                width="100"
                show-overflow-tooltip
            >
            </el-table-column>

            <el-table-column
                prop="createTime"
                label="登记时间"
                align="center"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="siteManager"
                label="现场管理员"
                align="center"
                width="100"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="dispatchName"
                label="派工人"
                align="center"
                width="100"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="dispatchTime"
                label="派工时间"
                align="center"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                prop="status"
                label="状态"
                align="center"
                width="100"
                show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <el-tag v-if="row.statusName" :type="row.statusName[row.statusName.length-1]=='中'?'warning':(row.statusName[row.statusName.length-1]=='成'?'success':'')">{{ row.statusName }}</el-tag>
              </template>
            </el-table-column>

            <el-table-column prop="operation" label="操作" align="left" width="250">
              <template slot-scope="{ row }">
                <el-button size="small" type="primary" @click="seeDetil(row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
                :current-page="search.current"
                :background="true"
                v-if="activeName!='first'"
                :page-sizes="[ 30, 50, 100]"
                :page-size="search.size"
                layout="total, prev, pager, next, sizes, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
            title="竣工资料配置"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            width="95%"
            @close="closeDialog"
    >
      <div style="display: flex;">
        <div class="contain2" style="flex: 3;">
          <div class="tableTool">
            <el-button type="primary" plain @click="onAddNewTemplate" size="mini">添加</el-button>
          </div>
          <el-table
                  :data="sourcetableData"
                  border
                  stripe
                  height="400"
                  :header-cell-style="{ background: '#f8f8f9' }"
          >
            <el-table-column
                    label="序号"
                    align="center"
                    type="index"
            >
            </el-table-column>
            <el-table-column
                    label="项目名称"
                    align="center"
            >
              <template slot-scope="{ row }">
                <div v-if="row.isUpdate==1">
                  {{row.templateName}}
                </div>
                <div v-else>
                  <el-input size="small" v-model="row.templateName" placeholder="请输入项目名称" show-word-limit></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                    label="项目描述"
                    align="center"
            >
              <template slot-scope="{ row }">
                <div v-if="row.isUpdate==1">
                  {{row.templateDescribe}}
                </div>
                <div v-else>
                  <el-input size="small" v-model="row.templateDescribe" placeholder="请输入项目描述" show-word-limit></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                    label="负责施工队"
                    align="center"
            >
              <template slot-scope="{ row }">
                <div v-if="row.isUpdate==1">
                  <span v-if="row.constructionType==1">安装施工队</span>
                  <span v-if="row.constructionType==2">土方施工队</span>
                  <span v-if="row.constructionType==3">定向钻施工队</span>
                </div>
                <div v-else>
                  <el-select size="small" v-model="row.constructionType" placeholder="请选择施工队">
                    <el-option label="安装施工队" :value="1"></el-option>
                    <el-option label="土方施工队" :value="2"></el-option>
                    <el-option label="定向钻施工队" :value="3"></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="left" width="300">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="saveRow(scope.row)" v-if="scope.row.isUpdate!=1">保 存</el-button>
                <el-button type="danger" size="small"  @click="quxiao(scope.$index, sourcetableData,scope.row)"  v-if="scope.row.isUpdate!=1">取 消</el-button>
                <el-button type="primary" size="small" @click="showSon(scope.row,scope.$index)" v-if="scope.row.isUpdate==1">操作子项</el-button>
                <el-button type="primary" size="small" v-if="scope.row.isUpdate==1" @click="modifyMode(scope.row)">编 辑</el-button>
                <el-button type="danger" size="small" @click="deleteRow(scope.$index, sourcetableData,scope.row)" v-if="scope.row.isUpdate==1">移 除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="contain2" style="flex: 2;">
          <div class="tableTool">
            <el-button type="primary" plain @click="twoAddNewTemplate" size="mini">添加</el-button>
            一级项目模板名称：{{showTemplateName}}
          </div>
          <el-table
                  :data="sonTableData"
                  border
                  stripe
                  height="400"
                  :header-cell-style="{ background: '#f8f8f9' }"
          >
            <el-table-column
                    label="序号"
                    align="center"
                    type="index"
            >
            </el-table-column>
            <el-table-column
                    label="子项目名称"
                    align="center"
            >
              <template slot-scope="{ row }">
                <div v-if="row.isUpdate==1">
                  {{row.templateSeedName}}
                </div>
                <div v-else>
                  <el-input size="small" v-model="row.templateSeedName" placeholder="请输入内容" show-word-limit></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                    label="类型"
                    align="center"
            >
              <template slot-scope="{ row }">
                <div v-if="row.isUpdate==1">
                  <span v-if="row.templateSeedType==1">图片</span>
                  <span v-if="row.templateSeedType==2">文本描述</span>
                  <span v-if="row.templateSeedType==3">PDF</span>
                </div>
                <div v-else>
                  <el-select size="small" v-model="row.templateSeedType" placeholder="请选择">
                    <el-option label="图片" :value="1"></el-option>
                    <el-option label="文本描述" :value="2"></el-option>
                    <el-option label="PDF" :value="3"></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                    label="必填项"
                    align="center"
            >
              <template slot-scope="{ row }">
                <div v-if="row.isUpdate==1">
                  <span v-if="row.templateSeedRequired==1">是</span>
                  <span v-if="row.templateSeedRequired==2">否</span>
                </div>
                <div v-else>
                  <el-select size="small" v-model="row.templateSeedRequired" placeholder="请选择">
                    <el-option label="是" :value="1"></el-option>
                    <el-option label="否" :value="2"></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="left" width="200">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="sonsaveRow(scope.row)" v-if="scope.row.isUpdate!=1">保 存</el-button>
                <el-button type="danger" size="small" @click="quxiao(scope.$index, sonTableData,scope.row)" v-if="scope.row.isUpdate!=1">取 消</el-button>
                <el-button type="primary" size="small" v-if="scope.row.isUpdate==1" @click="modifyModeson(scope.row)">编 辑</el-button>
                <el-button type="danger" size="small" v-if="scope.row.isUpdate==1" @click="sondeleteRow(scope.$index, sonTableData,scope.row)">移 除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">关 闭</el-button>
    <el-button type="primary" @click="saveMode" v-if="activeName=='first'">保 存</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {finishTemplateByProject, templateByProject,projectStatus} from "@/RequestPort/earlier";
import { getDict } from "@/apis/commonType.js";
import mixins from './mixin/mixins'
export default {
  name: "menuModeConfig",
  mixins:[mixins],
  data(){
    return{
      statusList:[],
      TypeList:[],
      activeName:'first',
      search:{
        size: 100,
        current:1
      },
      searchfirst:{
        size: 100,
        current:1
      },
      recordList:[],
      tableData:[],
      total:null
    }
  },
  mounted() {
    projectStatus().then((res) => {
      this.statusList = res.data;
    });
    // 工程类型
    getDict({ parentCode: 2100 }).then((res) => {
      this.TypeList = res.data;
    });
    this.getList()
  },
  methods:{
    handleSizeChange(val) {
      this.search.size = val
      this.getList(1)
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.getList()
    },
    handlefirstSizeChange(val) {
      this.searchfirst.size = val
      this.getList(1)
    },
    handlefirstCurrentChange(val) {
      this.searchfirst.current = val
      this.getList()
    },
    handleClick(){
      this.reset()
    },
    //重置查询条件
    reset() {
      this.search = {
        current: 1,
        size: 100,
      }
      this.getList()
    },
    getList(current) {
      if (current) {
        this.search.current = 1
        this.searchfirst.current = 1
      }
      //查列表
      if(this.activeName=='first'){
        templateByProject(this.searchfirst).then(res => {
          this.tableData = res.data.records
          this.total = res.data.total
        })
      }else{
        finishTemplateByProject(this.search).then(res => {
          this.recordList = res.data.records
          this.total = res.data.total
        })
      }

    },
  }
}
</script>

<style scoped>
.table {
  padding: 10px;
  border-radius: 5px;

  background-color: #ffffff;
  height: auto;
}
.content-design {
   background: #fff;
   border-radius: 6px;
   margin:10px;
 }
.all{height: auto;}
</style>